var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    staticClass: "f-dialog",
    attrs: {
      "title": "编辑“自定义”渠道",
      "width": "500px",
      "close-on-click-modal": false
    },
    on: {
      "open": _vm.openDialog
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('el-form', {
    ref: "editForm",
    attrs: {
      "model": _vm.editFrom,
      "label-width": "120px"
    }
  }, [_c('el-form-item', {
    staticStyle: {
      "padding-right": "50px"
    },
    attrs: {
      "label": "渠道名称：",
      "prop": "channelName",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.editFrom.channelName,
      callback: function callback($$v) {
        _vm.$set(_vm.editFrom, "channelName", $$v);
      },
      expression: "editFrom.channelName"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "渠道方联系人：",
      "prop": "channelContactPhone",
      "rules": [_vm.rules.tel]
    }
  }, [_c('div', {
    staticClass: "x aic"
  }, [_c('el-input', {
    staticStyle: {
      "flex": "2",
      "margin-right": "10px"
    },
    attrs: {
      "placeholder": "姓名"
    },
    model: {
      value: _vm.editFrom.channelContactName,
      callback: function callback($$v) {
        _vm.$set(_vm.editFrom, "channelContactName", $$v);
      },
      expression: "editFrom.channelContactName"
    }
  }), _c('el-input', {
    staticStyle: {
      "flex": "3"
    },
    attrs: {
      "placeholder": "联系电话"
    },
    model: {
      value: _vm.editFrom.channelContactPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.editFrom, "channelContactPhone", $$v);
      },
      expression: "editFrom.channelContactPhone"
    }
  }), _c('div', {
    staticClass: "del-icon x1 aic"
  })], 1)]), _c('el-form-item', {
    staticStyle: {
      "padding-right": "50px"
    },
    attrs: {
      "label": "渠道状态：",
      "prop": "isEnabled",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.editFrom.isEnabled,
      callback: function callback($$v) {
        _vm.$set(_vm.editFrom, "isEnabled", $$v);
      },
      expression: "editFrom.isEnabled"
    }
  }, [_c('el-radio-button', {
    attrs: {
      "label": true
    }
  }, [_vm._v("开启")]), _c('el-radio-button', {
    attrs: {
      "label": false
    }
  }, [_vm._v("关闭")])], 1)], 1)], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v("取 消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.editDefineChannel
    }
  }, [_vm._v("确 定")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }