var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "channel"
  }, [_c('div', {
    staticClass: "dao-title"
  }, [_vm._v(" 渠道设置 ")]), _c('div', {
    staticClass: "channel-main"
  }, [_c('div', {
    staticClass: "color9 mt10"
  }, [_vm._v(" 除“房易宝/快报备”之外，更多可分销渠道，由该项目“楼盘字典-基础信息-基本信息-关联平台”决定； ")]), _c('div', {
    staticClass: "tabs"
  }, [_c('el-radio-group', {
    staticClass: "f-radio-oval",
    on: {
      "change": _vm.switchChannel
    },
    model: {
      value: _vm.channelIndex,
      callback: function callback($$v) {
        _vm.channelIndex = $$v;
      },
      expression: "channelIndex"
    }
  }, _vm._l(_vm.channelList, function (item, index) {
    return _c('el-radio', {
      key: index,
      attrs: {
        "label": index,
        "border": ""
      }
    }, [_c('div', {
      staticClass: "title"
    }, [_vm._v(_vm._s(item.letTitle))]), _c('p', [_vm._v(_vm._s(item.name))])]);
  }), 1), _vm.curChannel.channelSource == 'CHANNEL_VISIT' ? _c('el-button', {
    attrs: {
      "type": "primary",
      "icon": "el-icon-plus"
    },
    on: {
      "click": _vm.showAddDialog
    }
  }, [_vm._v(" 创建合作机构 ")]) : _vm._e()], 1), _vm.curChannel.channelSource != 'CHANNEL_VISIT' && _vm.channelList && _vm.channelList.length ? _c('div', {
    staticClass: "channel-wrapper"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('strong', [_vm._v(" 分销状态："), _c('span', {
    class: _vm.channelStatus ? 'green' : 'red'
  }, [_vm._v(" " + _vm._s(_vm.channelStatus ? "已开通" : "已关闭") + " ")])]), _c('el-switch', {
    on: {
      "change": _vm.editChannel
    },
    model: {
      value: _vm.channelStatus,
      callback: function callback($$v) {
        _vm.channelStatus = $$v;
      },
      expression: "channelStatus"
    }
  })], 1), _c('p', {
    staticClass: "status"
  }, [_vm._v(" " + _vm._s(_vm.channelStatus ? "“已开通”状态下，该渠道用户可以查看项目相关分销政策，以及提交报备带看；" : "“已关闭”状态下，该渠道用户无法查看项目相关分销政策，以及提交报备带看，仅作为一般项目展示；") + " ")]), _vm.channelStatus ? _c('div', {
    staticClass: "progress"
  }, [_vm._m(0), _c('dl', [_c('dt', [_c('el-radio', {
    attrs: {
      "label": 0
    },
    on: {
      "change": _vm.editChannel
    },
    model: {
      value: _vm.takeProcess,
      callback: function callback($$v) {
        _vm.takeProcess = $$v;
      },
      expression: "takeProcess"
    }
  }, [_vm._v(" 通用型"), _c('span', [_vm._v("适用于一般分销项目")])])], 1), _vm._m(1)]), _vm.curChannel.channelSource === 1 ? _c('dl', [_c('dt', [_c('el-radio', {
    attrs: {
      "label": 1
    },
    on: {
      "change": _vm.editChannel
    },
    model: {
      value: _vm.takeProcess,
      callback: function callback($$v) {
        _vm.takeProcess = $$v;
      },
      expression: "takeProcess"
    }
  }, [_vm._v(" 包销型"), _c('span', [_vm._v("适用于包销项目，或内场合作项目；")])])], 1), _vm._m(2)]) : _vm._e()]) : _vm._e()]) : _vm._e(), _vm.curChannel.channelSource == 'CHANNEL_VISIT' ? [_c('define-channel-list', {
    ref: "defindeChannel"
  })] : _vm._e()], 2)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "title"
  }, [_c('strong', [_vm._v("带看流程")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('dd', [_c('ul', [_c('li', [_c('h5', [_vm._v("提交报备")]), _c('p', [_vm._v("经纪人")])]), _c('li', [_c('h5', [_vm._v("人工审核")]), _c('p', [_vm._v("驻场客服")])]), _c('li', [_c('h5', [_vm._v("上传带看码")]), _c('p', [_vm._v("驻场客服")])]), _c('li', [_c('h5', [_vm._v("上传带看凭证")]), _c('p', [_vm._v("经纪人")])]), _c('li', [_c('h5', [_vm._v("完成")])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('dd', [_c('ul', [_c('li', [_c('h5', [_vm._v("提交报备")]), _c('p', [_vm._v("经纪人")])]), _c('li', [_c('h5', [_vm._v("自动审核优先")]), _c('p', [_vm._v("销秘")])]), _c('li', [_c('h5', [_vm._v("生成带看码")]), _c('p', [_vm._v("系统")])]), _c('li', [_c('h5', [_vm._v("扫码签到")]), _c('p', [_vm._v("销秘")])]), _c('li', [_c('h5', [_vm._v("客户判定")]), _c('p', [_vm._v("置业顾问")])]), _c('li', [_c('h5', [_vm._v("带看确认")]), _c('p', [_vm._v("销秘")])]), _c('li', [_c('h5', [_vm._v("完成")])])]), _c('div', {
    staticClass: "tip"
  }, [_vm._v(" 注：“自动审核优先”即提交报备后，最近30天无带看数据，则自动通过审核；否则，需要人工根据实际报备规则进行审核； ")])]);

}]

export { render, staticRenderFns }