<template>
  <div class="channel">
    <div class="dao-title">
      渠道设置
    </div>
    <div class="channel-main">
      <div class="color9 mt10">
        除“房易宝/快报备”之外，更多可分销渠道，由该项目“楼盘字典-基础信息-基本信息-关联平台”决定；
      </div>
      <div class="tabs">
        <el-radio-group
          v-model="channelIndex"
          class="f-radio-oval"
          @change="switchChannel"
        >
          <el-radio
            :label="index"
            border
            v-for="(item, index) in channelList"
            :key="index"
          >
            <div class="title">{{ item.letTitle }}</div>
            <p>{{ item.name }}</p>
          </el-radio>
        </el-radio-group>
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="showAddDialog"
          v-if="curChannel.channelSource == 'CHANNEL_VISIT'"
        >
          创建合作机构
        </el-button>
      </div>
      <div
        class="channel-wrapper"
        v-if="
          curChannel.channelSource != 'CHANNEL_VISIT' &&
            channelList &&
            channelList.length
        "
      >
        <div class="title">
          <strong>
            分销状态：<span :class="channelStatus ? 'green' : 'red'">
              {{ channelStatus ? "已开通" : "已关闭" }}
            </span>
          </strong>
          <el-switch @change="editChannel" v-model="channelStatus"> </el-switch>
        </div>
        <p class="status">
          {{
            channelStatus
              ? "“已开通”状态下，该渠道用户可以查看项目相关分销政策，以及提交报备带看；"
              : "“已关闭”状态下，该渠道用户无法查看项目相关分销政策，以及提交报备带看，仅作为一般项目展示；"
          }}
        </p>
        <!--progress-->
        <div class="progress" v-if="channelStatus">
          <div class="title">
            <strong>带看流程</strong>
          </div>
          <dl>
            <dt>
              <el-radio v-model="takeProcess" :label="0" @change="editChannel">
                通用型<span>适用于一般分销项目</span>
              </el-radio>
            </dt>
            <dd>
              <ul>
                <li>
                  <h5>提交报备</h5>
                  <p>经纪人</p>
                </li>
                <li>
                  <h5>人工审核</h5>
                  <p>驻场客服</p>
                </li>
                <li>
                  <h5>上传带看码</h5>
                  <p>驻场客服</p>
                </li>
                <li>
                  <h5>上传带看凭证</h5>
                  <p>经纪人</p>
                </li>
                <li>
                  <h5>完成</h5>
                </li>
              </ul>
            </dd>
          </dl>
          <dl v-if="curChannel.channelSource === 1">
            <dt>
              <el-radio v-model="takeProcess" :label="1" @change="editChannel">
                包销型<span>适用于包销项目，或内场合作项目；</span>
              </el-radio>
            </dt>
            <dd>
              <ul>
                <li>
                  <h5>提交报备</h5>
                  <p>经纪人</p>
                </li>
                <li>
                  <h5>自动审核优先</h5>
                  <p>销秘</p>
                </li>
                <li>
                  <h5>生成带看码</h5>
                  <p>系统</p>
                </li>
                <li>
                  <h5>扫码签到</h5>
                  <p>销秘</p>
                </li>
                <li>
                  <h5>客户判定</h5>
                  <p>置业顾问</p>
                </li>
                <li>
                  <h5>带看确认</h5>
                  <p>销秘</p>
                </li>
                <li>
                  <h5>完成</h5>
                </li>
              </ul>
              <div class="tip">
                注：“自动审核优先”即提交报备后，最近30天无带看数据，则自动通过审核；否则，需要人工根据实际报备规则进行审核；
              </div>
            </dd>
          </dl>
        </div>
        <!--progress end-->
      </div>
      <template v-if="curChannel.channelSource == 'CHANNEL_VISIT'">
        <define-channel-list ref="defindeChannel"></define-channel-list>
      </template>
    </div>
  </div>
</template>

<script>
import {
  getEnterpriseChannelStatus,
  changeEnterpriseChannelStatus
} from "@/api/distribution";
import DefineChannelList from "./_components/list";
export default {
  data() {
    return {
      channelIndex: 0,
      curChannel: {}, // 当前渠道
      channelStatus: null, // 当前渠道状态
      channelList: [], // 渠道列表
      tableList: [],
      takeProcess: 0 // 带看流程: 0: 通用型, 1: 包销型
    };
  },
  components: {
    DefineChannelList
  },
  computed: {
    estateInfo() {
      return this.$store.state.estateInfo;
    }
  },
  created() {
    this.getEnterpriseChannelStatus();
  },
  methods: {
    showAddDialog() {
      this.$refs.defindeChannel.showAddForm();
    },
    async getEnterpriseChannelStatus() {
      // 获取渠道信息
      const res = await getEnterpriseChannelStatus({
        channelSource: 1,
        estateId: this.estateInfo.estateId,
        platformId: this.$platformInfo.id
      });
      if (res) {
        this.channelList = res.channelVos;
        this.switchChannel(); // 切换渠道,存储当前渠道状态
      }
    },
    switchChannel() {
      // 切换渠道,存储当前渠道状态
      this.curChannel = this.channelList[this.channelIndex];
      this.channelStatus = this.curChannel.isEnabled;
      this.takeProcess =
        this.curChannel.takeProcess == null ? 0 : this.curChannel.takeProcess; // 没有设置过，默认选中"通用型"
    },
    async editChannel() {
      // 更新渠道状态
      let query = {
        estateId: this.estateInfo.estateId,
        isEnabled: this.channelStatus,
        id: this.curChannel.id,
        takeProcess: this.takeProcess // 带看流程: 0: 通用型, 1: 包销型
      };
      const res = await changeEnterpriseChannelStatus(query);
      if (res) {
        this.getEnterpriseChannelStatus();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.channel {
  padding: 24px;
  background: #ffffff;
  box-shadow: 0 0 5px #eee;
  border-radius: 5px;
  &-main {
    padding-left: 13px;
  }
}
.tabs {
  display: flex;
  justify-content: space-between;
  margin: 0 0 30px 0;
  .el-radio-group {
    display: flex;
    flex-wrap: wrap;
  }
  .el-radio {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 20px 0 10px;
    margin-top: 20px;
    .title {
      font-size: 14px;
      color: #333;
      line-height: 100%;
    }
    p {
      font-size: 13px;
      color: #aaa;
      line-height: 100%;
      margin-top: 5px;
    }
  }
  .f-radio-oval {
    .el-radio.is-bordered {
      border-radius: 24px;
    }
    .el-radio.is-checked {
      background-color: #e1eafe;
    }
  }
}
::v-deep .el-radio.is-bordered {
  & + .el-radio.is-bordered {
    margin-left: 0;
  }
  margin-right: 10px;
}
.channel-wrapper {
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 20px 40px;
  ::v-deep .el-radio__input.is-checked + .el-radio__label {
    color: #333;
  }
  .title {
    @include flex-ycb;
  }
  .status {
    color: #999;
    margin-top: 20px;
  }
  .progress {
    padding: 30px 0;
    margin-top: 30px;
    border-top: 1px solid #eee;
  }
  ul {
    display: flex;
  }
  dl {
    margin-top: 30px;
  }
  dt {
    font-size: 14px;
    color: #333;
    span {
      color: #999;
      margin-left: 15px;
    }
  }
  dd {
    margin-top: 20px;
  }
  .tip {
    font-size: 12px;
    color: #999;
    margin-top: 20px;
  }
  li {
    @include flex-xyc2;
    background-color: #f2f2f2;
    width: 95px;
    height: 45px;
    flex: 0 0 auto;
    border-radius: 4px;
    position: relative;
    h5 {
      font-size: 14px;
      color: #666;
      font-weight: normal;
      line-height: 100%;
    }
    p {
      font-size: 12px;
      color: #999;
      line-height: 100%;
      margin-top: 3px;
    }
    &:last-child {
      width: 45px;
      border-radius: 50%;
    }
  }
  li + li {
    margin-left: 55px;
    &:before {
      display: block;
      content: "";
      width: 55px;
      height: 1px;
      background-color: #ddd;
      position: absolute;
      left: -55px;
    }
    &:after {
      display: block;
      content: "";
      width: 6px;
      height: 6px;
      border: 1px solid #ddd;
      border-bottom: 0;
      border-left: 0;
      position: absolute;
      left: -8px;
      transform: rotate(45deg);
    }
    h5:before {
      display: block;
      content: "";
      width: 7px;
      height: 7px;
      background-color: #ddd;
      border-radius: 50%;
      position: absolute;
      left: -55px;
      top: 50%;
      margin-top: -3px;
    }
  }
}
</style>
