var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('el-table', {
    staticClass: "mt20",
    attrs: {
      "data": _vm.tableList,
      "row-class-name": _vm.tableRowClassName
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "机构名称",
      "width": "145"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.row.channelName) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "联系方式",
      "width": "250"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.row.channelContactName) + "，" + _vm._s(_vm._f("formatPhone")(scope.row.channelContactPhone)) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "状态"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.row.isEnabled ? "开启" : "关闭") + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "更新时间",
      "width": "250"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.row.lastEditDate) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "更新人"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.row.lastEditUser) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "操作",
      "width": "250"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.editItem(scope.row);
            }
          }
        }, [_vm._v("编辑")]), _c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.openCloseDefineChannel(scope.row);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.isEnabled ? "关闭" : "开启") + " ")]), _c('el-button', {
          staticClass: "red",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteDefineChannel(scope.row);
            }
          }
        }, [_vm._v(" 删除 ")])];
      }
    }])
  })], 1), _vm.showAddDialog ? _c('add', {
    attrs: {
      "visible": _vm.showAddDialog
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.showAddDialog = $event;
      },
      "refresh": _vm.getDefineChannelList
    }
  }) : _vm._e(), _c('edit', {
    attrs: {
      "visible": _vm.showEditDialog,
      "options": _vm.curRow
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.showEditDialog = $event;
      },
      "refresh": _vm.getDefineChannelList
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "channel-intro"
  }, [_c('i', {
    staticClass: "el-icon-info"
  }), _c('strong', [_vm._v("房易宝/快报备：")]), _vm._v(" 适用于单项目开发商或资方，面向外部合作分销渠道，渠道经纪人通过“快报备小程序”进行报备带看 ")]);

}]

export { render, staticRenderFns }