<template>
  <el-dialog
    v-bind="$attrs"
    v-on="$listeners"
    class="f-dialog"
    title="编辑“自定义”渠道"
    width="500px"
    :close-on-click-modal="false"
    @open="openDialog"
  >
    <el-form :model="editFrom" label-width="120px" ref="editForm">
      <el-form-item
        style="padding-right:50px"
        label="渠道名称："
        prop="channelName"
        :rules="[rules.required()]"
      >
        <el-input
          placeholder="请输入"
          v-model="editFrom.channelName"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="渠道方联系人："
        prop="channelContactPhone"
        :rules="[rules.tel]"
      >
        <div class="x aic">
          <el-input
            style="flex:2;margin-right:10px"
            placeholder="姓名"
            v-model="editFrom.channelContactName"
          ></el-input>
          <el-input
            style="flex:3"
            placeholder="联系电话"
            v-model="editFrom.channelContactPhone"
          ></el-input>
          <div class="del-icon x1 aic"></div>
        </div>
      </el-form-item>
      <el-form-item
        style="padding-right:50px"
        label="渠道状态："
        prop="isEnabled"
        :rules="[rules.required()]"
      >
        <el-radio-group v-model="editFrom.isEnabled">
          <el-radio-button :label="true">开启</el-radio-button>
          <el-radio-button :label="false">关闭</el-radio-button>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="editDefineChannel">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import rules from "@/utils/rules";
import { editDefineChannel } from "@/api/distribution";
export default {
  props: {
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      rules,
      editFrom: {}
    };
  },
  computed: {
    estateInfo() {
      return this.$store.state.estateInfo;
    }
  },
  methods: {
    openDialog() {
      this.editFrom = { ...this.options };
    },
    async editDefineChannel() {
      // 编辑“自定义”渠道
      this.$refs.editForm.validate(async valid => {
        if (valid) {
          const data = await editDefineChannel(this.editFrom);
          if (data) {
            this.cancel();
            this.$emit("refresh");
          }
        }
      });
    },
    cancel() {
      this.$emit("update:visible", false);
    }
  }
};
</script>

<style lang="scss" scoped></style>
