<template>
  <div>
    <div class="channel-intro">
      <i class="el-icon-info"></i>
      <strong>房易宝/快报备：</strong>
      适用于单项目开发商或资方，面向外部合作分销渠道，渠道经纪人通过“快报备小程序”进行报备带看
    </div>
    <el-table
      :data="tableList"
      class="mt20"
      :row-class-name="tableRowClassName"
    >
      <el-table-column label="机构名称" width="145">
        <template slot-scope="scope">
          {{ scope.row.channelName }}
        </template>
      </el-table-column>
      <el-table-column label="联系方式" width="250">
        <template slot-scope="scope">
          {{ scope.row.channelContactName }}，{{
            scope.row.channelContactPhone | formatPhone
          }}
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          {{ scope.row.isEnabled ? "开启" : "关闭" }}
        </template>
      </el-table-column>
      <el-table-column label="更新时间" width="250">
        <template slot-scope="scope">
          {{ scope.row.lastEditDate }}
        </template>
      </el-table-column>
      <el-table-column label="更新人">
        <template slot-scope="scope">
          {{ scope.row.lastEditUser }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250">
        <template slot-scope="scope">
          <el-button type="text" @click="editItem(scope.row)">编辑</el-button>
          <el-button type="text" @click="openCloseDefineChannel(scope.row)">
            {{ scope.row.isEnabled ? "关闭" : "开启" }}
          </el-button>

          <el-button
            type="text"
            class="red"
            @click="deleteDefineChannel(scope.row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <add
      :visible.sync="showAddDialog"
      v-if="showAddDialog"
      @refresh="getDefineChannelList"
    ></add>
    <edit
      :visible.sync="showEditDialog"
      :options="curRow"
      @refresh="getDefineChannelList"
    ></edit>
  </div>
</template>

<script>
import {
  getDefineChannelList,
  createDefineChannelBatch,
  deleteDefineChannel,
  openCloseDefineChannel
} from "@/api/distribution";
import Edit from "./edit";
import Add from "./add";
export default {
  data() {
    return {
      tableList: [],
      showEditDialog: false,
      showAddDialog: false,
      curRow: {}
    };
  },
  components: {
    Edit,
    Add
  },
  computed: {
    estateInfo() {
      return this.$store.state.estateInfo;
    }
  },
  created() {
    this.getDefineChannelList();
  },
  methods: {
    // 给行添加className
    tableRowClassName({ row }) {
      if (!row.isEnabled) {
        return "disabled";
      }
    },
    showAddForm() {
      this.showAddDialog = true;
    },
    async getDefineChannelList() {
      // 获取自定义渠道
      let query = { estateId: this.estateInfo.estateId };
      const data = await getDefineChannelList(query);
      this.tableList = data;
    },
    async deleteDefineChannel(row) {
      // 删除自定义渠道
      this.$showConfirm(async () => {
        const data = await deleteDefineChannel(row.id);
        if (data) {
          this.getDefineChannelList();
        }
      });
    },
    async openCloseDefineChannel(row) {
      // 切换自定义渠道状态
      let query = {
        id: row.id,
        isEnabled: !row.isEnabled
      };
      const data = await openCloseDefineChannel(query);
      if (data) {
        this.getDefineChannelList();
      }
    },
    editItem(row) {
      this.showEditDialog = true;
      this.curRow = row;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-table tr.disabled {
  color: #aaa;
}
.channel-intro {
  font-size: 14px;
  color: #aaa;
  i {
    margin-right: 5px;
  }
}
</style>
