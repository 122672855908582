<template>
  <el-dialog
    v-bind="$attrs"
    v-on="$listeners"
    class="f-dialog"
    title="批量创建“自定义”渠道"
    width="500px"
    :close-on-click-modal="false"
  >
    <el-form :model="createForm" ref="addForm" label-width="120px">
      <div v-for="(item, index) in createForm.channelList" :key="index">
        <el-form-item
          style="padding-right:50px"
          label="渠道名称："
          :prop="`channelList.${index}.channelName`"
          :rules="[rules.required()]"
        >
          <el-input placeholder="请输入" v-model="item.channelName"></el-input>
        </el-form-item>
        <el-form-item
          label="渠道方联系人："
          :prop="`channelList.${index}.channelContactPhone`"
          :rules="[rules.tel]"
        >
          <div class="x aic">
            <el-input
              style="flex:2;margin-right:10px"
              placeholder="姓名"
              v-model="item.channelContactName"
            ></el-input>
            <el-input
              style="flex:3"
              placeholder="联系电话"
              v-model="item.channelContactPhone"
            ></el-input>
            <div class="del-icon x1 aic">
              <svg-icon
                icon-class="delete2"
                class="pointer"
                v-if="
                  index + 1 === createForm.channelList.length &&
                    createForm.channelList.length > 1
                "
                @click="delItem(index)"
              ></svg-icon>
            </div>
          </div>
        </el-form-item>
      </div>
    </el-form>
    <el-button @click="addItem" style="margin-left:120px;background:#f2f2f2;">
      添加更多
    </el-button>
    <div slot="footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="createDefineChannelBatch">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import rules from "@/utils/rules";
import { createDefineChannelBatch } from "@/api/distribution";
const defaultCreateForm = {
  channelList: [
    {
      channelName: "",
      channelContactName: "",
      channelContactPhone: ""
    }
  ]
};
export default {
  data() {
    return {
      rules,
      createForm: JSON.parse(JSON.stringify(defaultCreateForm))
    };
  },
  computed: {
    estateInfo() {
      return this.$store.state.estateInfo;
    }
  },
  created() {
    this.createForm.channelList[0].estateId = this.estateInfo.estateId;
  },
  methods: {
    async createDefineChannelBatch() {
      //创建自定义渠道
      this.$refs.addForm.validate(async valid => {
        if (valid) {
          const data = await createDefineChannelBatch(this.createForm);
          if (data) {
            this.cancel();
            this.$emit("refresh");
          }
        }
      });
    },
    addItem() {
      this.createForm.channelList.push({
        channelName: "",
        channelContactName: "",
        channelContactPhone: "",
        estateId: this.estateInfo.estateId
      });
    },
    delItem(index) {
      this.createForm.channelList.splice(index, 1);
    },
    cancel() {
      this.$emit("update:visible", false);
    }
  }
};
</script>

<style lang="scss" scoped></style>
