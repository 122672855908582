var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    staticClass: "f-dialog",
    attrs: {
      "title": "批量创建“自定义”渠道",
      "width": "500px",
      "close-on-click-modal": false
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('el-form', {
    ref: "addForm",
    attrs: {
      "model": _vm.createForm,
      "label-width": "120px"
    }
  }, _vm._l(_vm.createForm.channelList, function (item, index) {
    return _c('div', {
      key: index
    }, [_c('el-form-item', {
      staticStyle: {
        "padding-right": "50px"
      },
      attrs: {
        "label": "渠道名称：",
        "prop": "channelList.".concat(index, ".channelName"),
        "rules": [_vm.rules.required()]
      }
    }, [_c('el-input', {
      attrs: {
        "placeholder": "请输入"
      },
      model: {
        value: item.channelName,
        callback: function callback($$v) {
          _vm.$set(item, "channelName", $$v);
        },
        expression: "item.channelName"
      }
    })], 1), _c('el-form-item', {
      attrs: {
        "label": "渠道方联系人：",
        "prop": "channelList.".concat(index, ".channelContactPhone"),
        "rules": [_vm.rules.tel]
      }
    }, [_c('div', {
      staticClass: "x aic"
    }, [_c('el-input', {
      staticStyle: {
        "flex": "2",
        "margin-right": "10px"
      },
      attrs: {
        "placeholder": "姓名"
      },
      model: {
        value: item.channelContactName,
        callback: function callback($$v) {
          _vm.$set(item, "channelContactName", $$v);
        },
        expression: "item.channelContactName"
      }
    }), _c('el-input', {
      staticStyle: {
        "flex": "3"
      },
      attrs: {
        "placeholder": "联系电话"
      },
      model: {
        value: item.channelContactPhone,
        callback: function callback($$v) {
          _vm.$set(item, "channelContactPhone", $$v);
        },
        expression: "item.channelContactPhone"
      }
    }), _c('div', {
      staticClass: "del-icon x1 aic"
    }, [index + 1 === _vm.createForm.channelList.length && _vm.createForm.channelList.length > 1 ? _c('svg-icon', {
      staticClass: "pointer",
      attrs: {
        "icon-class": "delete2"
      },
      on: {
        "click": function click($event) {
          return _vm.delItem(index);
        }
      }
    }) : _vm._e()], 1)], 1)])], 1);
  }), 0), _c('el-button', {
    staticStyle: {
      "margin-left": "120px",
      "background": "#f2f2f2"
    },
    on: {
      "click": _vm.addItem
    }
  }, [_vm._v(" 添加更多 ")]), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v("取 消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.createDefineChannelBatch
    }
  }, [_vm._v(" 确 定 ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }